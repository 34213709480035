import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

:root {
    --backgroundDark: #14213D; 
    --backgroundLight: #E5E5E5;
    --fontDark: ##1C1C1C;
    --fontLight: #E5E5E5;
    --accent: #FFCE26;
    //#14213D (blue), #20252D (grey), #1C1C1C charcoal, #154443 green
}
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;
}
body {
    font-size: 1.5rem;
    font-family: sans-serif;
    
    margin: 0 auto;
   // max-width: 1800px;
  
    
}
ul {
    list-style: none;
}
button, .button {
    background: var(--accent);
    //border: 2px solid var(--backgroundDark);
    border: none;
    cursor: pointer;
    color: var(--fontDark);
    border-radius: 3rem;
    transition: ease-in-out 0.2s;
    margin: 1rem;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  button:hover, .button:hover {
    background: var(--backgroundDark);
    color: var(--accent);
    transition: 0.4s ease-in-out;
    
    
  }
//select img before loaded
/*.gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }*/
  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
