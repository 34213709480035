import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import Partners from "./Partners";

const FooterStyles = styled.div`
  display: grid;
  li {
    padding: 7px;
    font-size: 1.5rem;
  }
  .footerHeader {
    padding-bottom: 1.5rem;
    font-size: 2rem;
    text-transform: uppercase;
    padding-top: 3rem;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
  }
  a {
    text-transform: capitalize;
  }
  .email {
    text-transform: lowercase;
  }
  @media (width > 800px) {
    grid-template-columns: repeat(3, 1fr);
    h1,
    .copyright,
    .partners {
      grid-column: span 3;
    }
    li {
      padding: 5px;
    }
  }

  background: var(--backgroundDark);
  color: var(--fontLight);
  text-align: center;
  padding: 2rem 1rem;
  @media (width > 1000px) {
    padding: 15rem 8rem 2rem;
  }

  .copyright {
    border-top: 2px solid var(--backgroundLight);
    padding: 2rem;
    margin-top: 2rem;
    font-size: 1rem;
  }
  h1 {
    padding: 4rem;
    border-bottom: 2px solid var(--backgroundLight);
  }
  //li
  .hover {
    cursor: pointer;
    display: inline;
  }
  //div
  .bubble {
    display: none;
    background-color: var(--backgroundLight);
    padding: 1rem;
    position: absolute;
    border-radius: 1rem;
    margin-top: 1rem;
    z-index: 1;
  }
  //div p
  .bubbleP {
    color: var(--backgroundDark);
    font-weight: bold;
    padding: 1rem;
  }
  //div child on hover of div
  .hover:hover :only-child {
    display: block;
  }
  @media (width > 1600px) {
    .bubble {
      margin-right: 15rem;
    }
  }
`;

export default function Footer() {
  const year = new Date().getFullYear();

  const footerQuery = useStaticQuery(graphql`
    query {
      footer: allSanityFooter {
        nodes {
          phone
          toll
          emailAddress
          address
          fax
          checkIn
          checkOut
          frontDesk
          groups
          cancellation
          reservation
          pet
          smoke
          otherPolicy
        }
      }
    }
  `);
  const footer = footerQuery.footer.nodes[0];

  return (
    <FooterStyles>
      <h1 id="contact">Sea to Sky Hotel & Conference Center</h1>
      <div>
        <h2 className="footerHeader">Contact</h2>
        <ul>
          <li>
            <a href={`tel:+1${footer.phone}`}>Tel: 604-898-4874</a>
          </li>
          <li>
            <a href={`tel:+${footer.toll}`}>Free: 1-800-531-1530</a>
          </li>

          <li>
            <a href={`mailto:${footer.emailAddress}`} className="email">
              {footer.emailAddress}
            </a>
          </li>
          <li>
            <a
              href="https://goo.gl/maps/7v1KtiPUNbw96PJs9"
              target="_blank"
              rel="noreferrer"
            >
              {footer.address}
            </a>
          </li>
          <li>Fax:{footer.fax}</li>
        </ul>
      </div>
      <div>
        <h2 className="footerHeader">Hours</h2>
        <ul>
          <li>Check in: {footer.checkIn}</li>
          <li>Check out: {footer.checkOut}</li>
          <li>{footer.frontDesk}</li>
        </ul>
      </div>
      <div>
        <h2 className="footerHeader">Policies</h2>
        <ul>
          <li className="hover">
            Reservation |
            <div className="bubble ">
              <p className="bubbleP">{footer.reservation}</p>
            </div>
          </li>
          <li className="hover">
            Smoking |
            <div className="bubble ">
              <p className="bubbleP">{footer.smoke}</p>
            </div>
          </li>
          <li className="hover">
            Pet |
            <div className="bubble ">
              <p className="bubbleP">{footer.pet}</p>
            </div>
          </li>

          {footer.otherPolicy ? (
            <li className="hover">
              Other |
              <div className="bubble ">
                <p className="bubbleP">{footer.otherPolicy}</p>
              </div>
            </li>
          ) : null}

          <li className="hover">
            Group Bookings
            <div className="bubble ">
              <p className="bubbleP">{footer.groups}</p>
            </div>
          </li>
        </ul>
      </div>
      <Partners />
      <div className="copyright">
        Copyright &#169; {year} | All Rights Reserved | Site developed by{" "}
        <a href="https://github.com/FreyaP" target="_blank" rel="noreferrer">
          Freya
        </a>
        <div>
          <a
            href="https://seatoskyhotel.sanity.studio/desk"
            target="_blank"
            rel="noreferrer"
          >
            Login
          </a>
        </div>
      </div>
    </FooterStyles>
  );
}
