import React from 'react';
import Nav from './Nav';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Footer from './Footer';

export default function Layout({ children }) {
    return (
        <div>
            <GlobalStyles />
            <Typography />
            <Nav />
            {children}
            <Footer />
        </div>
    )
}