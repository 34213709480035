import { createGlobalStyle } from "styled-components"
import Tinos from '../assets/fonts/Tinos-Regular.woff';
import OpenSans from '../assets/fonts/Open-Sans.woff';

const Typography = createGlobalStyle`
@font-face {
    font-family: Tinos;
    src: url(${Tinos});
}
@font-face {
    font-family: OpenSans;
    src: url(${OpenSans});
}
html {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    color: var(--fontDark);
}
a {
    color: var(--fontDark);
    text-decoration: none;
    //text-decoration-color: var(--accent);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
    text-transform: uppercase;
    font-weight: bold;
  }
  a:hover {
    color: var(--accent);
  }
  h1,h2,h3, h4 {
    font-weight: bold;
    margin: 0;
    font-family: Tinos, Georgia, 'Times New Roman', Times, serif;
    text-transform: uppercase;
    padding: 4rem 2rem;

  }
  h2 {
    font-size: 5rem;
  }
  
  p, li {
    letter-spacing: 0.5px;
    line-height: 1.5;
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    padding-right: 2rem;
    padding-left: 2rem;
    
  }
  .center {
    text-align: center;
  }
`;

export default Typography;
