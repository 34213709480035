import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo from "../assets/images/Sea-to-Sky-Hotel-Logo.svg";

const HeaderStyles = styled.div`
  //max-width: 1800px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 999;

  .button {
    margin: 2rem;
    padding: 1rem;
    border-radius: 3rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  .end {
    justify-self: end;
  }

  a {
    align-self: center;
    justify-self: center;
  }
  img {
    width: 5rem;
    height: 5rem;
  }
  @media (width > 900px) {
    img {
      width: 8rem;
      height: 8rem;
    }
    padding-top: 1rem;
  }
`;
const NavStyles = styled.nav`
  padding: 1rem 0;
`;
const HamburgerButtonStyles = styled.button`
  border: none;
  height: 3rem;
  width: 3rem;
  position: relative;
  font-size: 20px;
  display: block;
  background: transparent;

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
  }
  //remove global styles
  &:hover {
    background: transparent;
  }
`;
const LineStyles = styled.div`
  top: 50%;
  margin-top: -0.125em;

  &,
  &:after,
  &:before {
    height: 2px;
    pointer-events: none;
    display: block;
    content: "";
    width: 100%;
    background-color: var(--backgroundLight);
    position: absolute;
  }
  &:after {
    /* Move bottom line below center line */
    top: -0.8rem;
  }
  &:before {
    /* Move top line on top of center line */
    top: 0.8rem;
  }
`;
//opened menu items
const NavItems = styled.ul`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  background-color: var(--backgroundDark);
  color: var(--fontLight);
  padding: 1rem 4rem;
  transition: 0.5s ease-in-out;
  transform: ${({ openDrawer }) =>
    openDrawer ? `translateX(0)` : `translateX(-100%)`};

  @media (width > 1600px) {
    padding: 1rem 8rem;
    font-size: 2rem;
  }
`;
const NavSingleItem = styled.li`
  padding: 1.5rem 0;
  cursor: pointer;
  border-bottom: 1px solid var(--backgroundLight);
`;
export default function Nav() {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    /* Close the drawer when the user hovers outside of it */
    const closeDrawer = (event) => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }
      toggleDrawer(false);
    };

    document.addEventListener("mouseover", closeDrawer);
    return () => document.removeEventListener("mouseover", closeDrawer);
  }, []);
  return (
    <>
      <HeaderStyles>
        <NavStyles>
          <HamburgerButtonStyles
            type="button"
            name="menu"
            aria-label="Menu"
            onClick={toggleDrawer}
          >
            <LineStyles />
          </HamburgerButtonStyles>
          <NavItems ref={drawerRef} openDrawer={openDrawer}>
            <NavSingleItem>
              <Link to="/rooms">Rooms</Link>
            </NavSingleItem>
            <NavSingleItem>
              <Link to="/#amenities">Amenities</Link>
            </NavSingleItem>
            <NavSingleItem>
              <Link to="/#adventure">Adventure</Link>
            </NavSingleItem>
            <NavSingleItem>
              <Link to="#contact">Contact</Link>
            </NavSingleItem>
          </NavItems>
        </NavStyles>
        <Link to="/">
          <div>
            <img src={logo} alt="Hotel Logo" />
          </div>
        </Link>
        <a
          className="end"
          href="https://hotels.cloudbeds.com/reservation/opwn4d"
          target="_blank"
          rel="noreferrer"
        >
          <button className="button">Book Online</button>
        </a>
      </HeaderStyles>
    </>
  );
}
