import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const PartnerStyles = styled.div`

margin-top: 5rem;
margin-bottom: 5rem;
display: flex;
//grid-template-columns: repeat(5, 1fr);
gap: 3rem;
z-index: 0;



@media (width > 800px) {
    grid-column: span 3;
    justify-content: center;
    margin-top: 10rem;

    .gatsby-image-wrapper {
    max-width: 9rem;

}
}
`

export default function Partners() {

const partnerQuery = useStaticQuery(graphql`
query {
    partners: allSanityPartners {
    nodes {
      link
      image {
        asset {
          gatsbyImageData(width: 400)
          id
        }
      }
    }
  }
}
`)
const partners = partnerQuery.partners.nodes;

    return (
        <>
        <PartnerStyles>
        {partners.map((partner, index) => {
            return (
                <a href={partner.link} key={index}>
                    <GatsbyImage image={getImage(partner.image.asset)} alt="partner logo" key={partner.image.asset.id}/>
                </a>
            )
        })}
        </PartnerStyles>
        </>
    )
}